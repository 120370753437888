import { Rate, Row } from 'antd'
import { Key, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { useStudentTestsApiQuery } from '~/student-utils/api'
import {
  ageFromSchool,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import Api from '~/utils/api'
import { VIDEO_TYPE, currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { TTestResultWithPoint } from '~/utils/points'
import { IVideoRes } from '~/utils/types'
import Video from '../cards/Video'
import { VideoModal } from '../cards/VideoModal'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

const cardItems = [
  {
    title: 'エンジョイ',
    image: '/images/Video1.png',
    level: 1,
    color: '#D4F3D3',
  },
  {
    title: 'ステップアップ',
    image: '/images/Video2.png',
    level: 2,
    color: '#F3F2D3',
  },
  {
    title: 'アスリート',
    image: '/images/Video3.png',
    level: 3,
    color: '#F3D3D3',
  },
] as const

/**
 * Path: /videos
 */
const VideosPage = () => {
  const auth = useContext(AuthContext)

  const { t, i18n } = useTranslation()

  const isLevelB = auth.schoolLevel === 'B'
  const { me: meData } = useAlphaStore()
  const { data: examsData } = useStudentTestsApiQuery()
  const [testResult, setTestResult] = useState<null | TTestResultWithPoint>(
    null,
  )

  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp
  const isEnduranceRun = schoolHasEnduranceTest(meData?.school)

  const dataVideos = useMemo(
    () => [
      {
        key: 'GRIP_STRENGTH',
        keyValue: 'grip',
        rating: testResult?.grip?.rating || 0,
        sportName: '握力',
        title: isUsingJpRuby ? (
          <ruby>
            <span style={{ paddingLeft: 10, paddingRight: 10 }}>握力</span>
            <rt className="font-normal text-14-hina">あくりょく</rt>
          </ruby>
        ) : (
          t('握力')
        ),
      },
      {
        key: 'SIT_UPS',
        keyValue: 'sitUps',
        rating: testResult?.sitUps?.rating || 0,
        sportName: '上体起こし',
        title: isUsingJpRuby ? (
          <span>
            <ruby>
              <span>上体起</span>
              <rt className="font-normal text-14-hina">じょうたいお</rt>
            </ruby>
            こし
          </span>
        ) : (
          t('上体起こし')
        ),
      },
      {
        key: 'BENDING',
        keyValue: 'bending',
        rating: testResult?.bending?.rating || 0,
        sportName: '長座体前屈',
        title: isUsingJpRuby ? (
          <ruby>
            <span>長座体前屈</span>
            <rt className="font-normal text-14-hina">ちょうざたいぜんくつ</rt>
          </ruby>
        ) : (
          t('長座体前屈')
        ),
      },
      {
        key: 'SIDE_JUMP',
        keyValue: 'sideJump',
        rating: testResult?.sideJump?.rating || 0,
        sportName: '反復横とび',
        title: isUsingJpRuby ? (
          <span>
            <ruby>
              <span>反復横</span>
              <rt className="font-normal text-14-hina">はんぷくよこ</rt>
            </ruby>
            とび
          </span>
        ) : (
          t('反復横跳び')
        ),
      },
      {
        key: 'ENDURANCE_RUN',
        keyValue: 'enduranceRun',
        rating: testResult?.enduranceRun?.rating || 0,
        sportName: '持久走',
        title: t('持久走'),
      },
      {
        key: 'SHUTTLE_RUN',
        keyValue: 'shuttleRun',
        rating: testResult?.shuttleRun?.rating || 0,
        sportName: '20mシャトルラン',
        title: t('20mシャトルラン'),
      },
      {
        key: 'SPRINT_RUN',
        rating: testResult?.sprintRun?.rating || 0,
        sportName: '50m走',
        title: isUsingJpRuby ? (
          <span>
            50m
            <ruby>
              <span>走</span>
              <rt className="font-normal text-14-hina">そう</rt>
            </ruby>
          </span>
        ) : (
          t('50m走')
        ),
      },
      {
        key: 'STANDING_JUMP',
        keyValue: 'standingJump',
        rating: testResult?.standingJump?.rating || 0,
        sportName: '立ち幅とび',
        title: isUsingJpRuby ? (
          <span>
            <ruby>
              <span>立</span>
              <rt className="font-normal text-14-hina">た</rt>
            </ruby>
            ち
            <ruby>
              <span>幅</span>
              <rt className="font-normal text-14-hina">はば</rt>
            </ruby>
            とび
          </span>
        ) : (
          t('立ち幅跳び')
        ),
      },
      {
        key: 'HANDBALL_THROW',
        keyValue: 'handballThrow',
        rating: testResult?.handballThrow?.rating || 0,
        sportName: 'ボール投げ',
        title: isUsingJpRuby ? (
          <span>
            ボール
            <ruby>
              <span>投</span>
              <rt className="font-normal text-14-hina">な</rt>
            </ruby>
            げ
          </span>
        ) : (
          t('ボール投げ')
        ),
      },
    ],
    [isUsingJpRuby, t, testResult],
  )

  const [value, setValue] = useState('')
  const [dataRate, setDataRate] = useState(0)
  const [listVideos, setListVideos] = useState<IVideoRes[]>([])
  const [tabSelect, setTabSelect] = useState(dataVideos[0])
  const [selectedVideo, setSelectedVideo] = useState<IVideoRes | undefined>()

  const getVideoData = async (
    title: string,
    sportName: string,
    rating: number | undefined | null,
  ) => {
    try {
      setListVideos([])
      const apiUrl = `alpha/v1/student/listStudentVideo?type=${title}&sportName=${sportName}&rating=${
        rating || 3
      }`
      const { data } = await Api.get(apiUrl)
      setListVideos(data?.data ?? [])
    } catch (error) {
      setListVideos([])
    }
  }

  const changeTab = async (tab: {
    key: string
    keyValue?: string
    rating: number
    sportName: string
    title: string | JSX.Element
  }) => {
    setTabSelect(tab)
    setDataRate(tab?.rating || 0)
    getVideoData(value, tab?.sportName, tab?.rating)
  }

  const currentTests = isEnduranceRun
    ? [
        'GRIP_STRENGTH',
        'SIT_UPS',
        'BENDING',
        'SIDE_JUMP',
        'ENDURANCE_RUN',
        'SHUTTLE_RUN',
        'SPRINT_RUN',
        'STANDING_JUMP',
        'HANDBALL_THROW',
      ]
    : [
        'GRIP_STRENGTH',
        'SIT_UPS',
        'BENDING',
        'SIDE_JUMP',
        'SHUTTLE_RUN',
        'SPRINT_RUN',
        'STANDING_JUMP',
        'HANDBALL_THROW',
      ]

  useEffect(() => {
    if (!meData) {
      return
    }

    const testResult = examsData?.find(
      (d) => d.testYear === currentYear,
    )?.result

    if (!testResult) {
      return
    }

    const _testResult = reCalcTestResult(
      testResult,
      ageFromSchool(meData.school.schoolCategoryCode, meData.schoolGrade),
      meData?.gender,
      isEnduranceRun,
    )

    const video = dataVideos[0]
    setTestResult(_testResult)
    setDataRate(_testResult.grip?.rating || 0)
    setTabSelect({ ...video, rating: testResult.grip?.rating || 0 })

    if (value) {
      getVideoData(value, video?.sportName, testResult.grip?.rating)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examsData, value, isEnduranceRun, meData])

  const Card = (
    cardItem:
      | {
          readonly title: 'エンジョイ'
          readonly image: '/images/Video1.png'
          readonly level: 1
          readonly color: '#D4F3D3'
        }
      | {
          readonly title: 'ステップアップ'
          readonly image: '/images/Video2.png'
          readonly level: 2
          readonly color: '#F3F2D3'
        }
      | {
          readonly title: 'アスリート'
          readonly image: '/images/Video3.png'
          readonly level: 3
          readonly color: '#F3D3D3'
        },
    idx: Key | null | undefined,
  ) => (
    <div
      key={idx}
      className="w-56 h-96 pt-6 rounded-10px cursor-pointer mb-3"
      style={{ background: cardItem.color }}
      onClick={() => {
        setValue(cardItem.title)
      }}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{t(cardItem.title)}</span>
      </Row>
      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 246, paddingBottom: cardItem.level === 3 ? 28 : 0 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain' }}
        />
      </Row>
      <Row justify="center" className="w-full relative">
        {isUsingJpRuby ? (
          <div style={{ marginTop: '-35px' }}>
            <span className="text-12-hina" style={{ fontWeight: 700 }}>
              うんどうりょう
            </span>
          </div>
        ) : null}
        <span
          className="title-level absolute"
          style={{
            top: '-12px',
            background: '#036EB8',
            borderRadius: 10,
            color: '#ffffff',
            padding: '4px 12px',
            fontWeight: 700,
            fontSize: 14,
            lineHeight: '14px',
          }}
        >
          {t('運動量')}
        </span>
        <div
          className="flex justify-center"
          style={{
            background: '#FFFFFF',
            borderRadius: 4,
            border: '1px solid #036EB8',
            width: 160,
            height: 40,
          }}
        >
          <img
            src="/images/video_level.png"
            alt=""
            style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
          />
          {cardItem.level >= 2 && (
            <img
              src="/images/video_level.png"
              alt=""
              style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
            />
          )}
          {cardItem.level === 3 && (
            <img
              src="/images/video_level.png"
              alt=""
              style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
            />
          )}
        </div>
      </Row>
    </div>
  )
  const CardMobile = (
    cardItem:
      | {
          readonly title: 'エンジョイ'
          readonly image: '/images/Video1.png'
          readonly level: 1
          readonly color: '#D4F3D3'
        }
      | {
          readonly title: 'ステップアップ'
          readonly image: '/images/Video2.png'
          readonly level: 2
          readonly color: '#F3F2D3'
        }
      | {
          readonly title: 'アスリート'
          readonly image: '/images/Video3.png'
          readonly level: 3
          readonly color: '#F3D3D3'
        },
    idx: Key | null | undefined,
  ) => (
    <div
      key={idx}
      className="pt-3 pb-3 rounded-10px cursor-pointer mb-6"
      style={{ background: cardItem.color, width: '100%', height: 290 }}
      onClick={() => {
        setValue(cardItem.title)
      }}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{t(cardItem.title)}</span>
      </Row>
      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 160, paddingBottom: cardItem.level === 3 ? 28 : 0 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain', height: 160 }}
        />
      </Row>
      {isUsingJpRuby ? (
        <div
          className="text-center"
          style={{ marginBottom: '11px', marginTop: '-11px' }}
        >
          <span className="text-12-hina" style={{ fontWeight: 700 }}>
            うんどうりょう
          </span>
        </div>
      ) : null}
      <Row justify="center" className="w-full relative">
        <span
          className="title-level absolute"
          style={{
            top: '-12px',
            background: '#036EB8',
            borderRadius: 10,
            color: '#ffffff',
            padding: '4px 12px',
            fontWeight: 700,
            fontSize: 14,
            lineHeight: '14px',
          }}
        >
          {t('運動量')}
        </span>
        <div
          className="flex justify-center"
          style={{
            background: '#FFFFFF',
            borderRadius: 4,
            border: '1px solid #036EB8',
            width: 160,
            height: 40,
          }}
        >
          <img
            src="/images/video_level.png"
            alt=""
            style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
          />
          {cardItem.level >= 2 && (
            <img
              src="/images/video_level.png"
              alt=""
              style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
            />
          )}
          {cardItem.level === 3 && (
            <img
              src="/images/video_level.png"
              alt=""
              style={{ width: 34, height: 27, margin: '10px 8px 3px' }}
            />
          )}
        </div>
      </Row>
    </div>
  )

  const fittedMyselfTitleEle = (
    <div className="theme-nav-title">
      {isUsingJpRuby ? (
        <span>
          <ruby>
            <span>自分</span>
            <rt className="font-normal text-18-hina">じぶん</rt>
          </ruby>
          にあった
          <ruby>
            <span>動画</span>
            <rt className="font-normal text-18-hina">どうが</rt>
          </ruby>
        </span>
      ) : (
        t('自分にあった動画')
      )}
    </div>
  )

  return (
    <Dashboard
      selectedMenu={TabIndex.Movie}
      navbar={
        value ? (
          <div className="theme-nav-title">{t(value)}</div>
        ) : (
          fittedMyselfTitleEle
        )
      }
    >
      <div className="videos-page">
        <div className={value === '' ? '' : 'hidden'}>
          <div className="show-pc">
            <span
              className="text-center pt-12 block"
              style={{ fontWeight: 700, fontSize: 36, lineHeight: '32px' }}
            >
              {isUsingJpRuby ? (
                <span>
                  <ruby>
                    <span>自分</span>
                    <rt className="font-normal text-18-hina">じぶん</rt>
                  </ruby>
                  にあった
                  <ruby>
                    <span>目標</span>
                    <rt className="font-normal text-18-hina">もくひょう</rt>
                  </ruby>
                  を
                  <ruby>
                    <span>決</span>
                    <rt className="font-normal text-18-hina">き</rt>
                  </ruby>
                  めよう！
                </span>
              ) : (
                t('自分にあった目標を決めよう！')
              )}
            </span>
            <Row
              justify="center"
              align="middle"
              className="space-x-4 mt-12 p-3"
            >
              {cardItems.map((cardItem, idx) => Card(cardItem, idx))}
            </Row>
          </div>
          <div className="show-sp">
            <Row
              justify="center"
              align="middle"
              className="pt-6 m-auto"
              style={{ width: 'calc(100vw - 30px)' }}
            >
              {cardItems.map((cardItem, idx) => CardMobile(cardItem, idx))}
            </Row>
          </div>
        </div>
        <div className={value === '' ? 'hidden' : ''}>
          <div className="grid grid-cols-4 list-exam mb-6 mt-0">
            {dataVideos.map((video, index) => {
              if (!currentTests.includes(video.key)) {
                return false
              }
              return (
                <div
                  key={index}
                  className={`${
                    (video.key === 'SHUTTLE_RUN' && isUsingJp
                      ? 'font-run '
                      : '') + (tabSelect.key === video.key ? 'exam-active' : '')
                  } text-center`}
                  onClick={() => changeTab(video)}
                >
                  {video.title}
                </div>
              )
            })}
          </div>
          <div className="rating-level">
            <span className="mr-10">
              {isUsingJpRuby ? (
                <span>
                  あなたの
                  <ruby>
                    <span>評価</span>
                    <rt className="font-normal text-12-hina">ひょうか</rt>
                  </ruby>
                </span>
              ) : (
                t('あなたの評価')
              )}
            </span>
            <Rate disabled value={dataRate} />
          </div>
          <Row justify="center" className="pt-5 list-video">
            <div className="show-pc">
              <Row className="video-item-list">
                {listVideos.map((m, index) => (
                  <div
                    className={`${
                      listVideos.length < 3 ? 'w-1/2' : 'w-1/3'
                    } pl-2 mb-2 ${listVideos.length < 4 ? '' : 'video-page'}`}
                    key={index}
                    onClick={(event) => {
                      setSelectedVideo(m)
                      event.stopPropagation()
                    }}
                    onTouchEnd={(event) => {
                      setSelectedVideo(m)
                      event.stopPropagation()
                    }}
                  >
                    <Video
                      keyName={m._id}
                      videoType={VIDEO_TYPE.TEST_VIDEO}
                      src={m.url}
                      size={{ width: 292, height: 164 }}
                      thumb={m.thumb}
                      className="rounded-10px overflow-hidden pointer-events-none"
                    />
                    <Row className="justify-between px-3 text-black">
                      <div>{t(m.sportName)}</div>

                      <div>
                        {m.ability?.length > 0
                          ? m.ability.map((item, idx) => (
                              <div key={idx}>{t(item)}</div>
                            ))
                          : null}
                      </div>
                    </Row>
                  </div>
                ))}
              </Row>
            </div>

            <div className="show-sp">
              <div>
                {listVideos.map((m, index) => (
                  <div className="list-video mb-2" key={index}>
                    <Video
                      keyName={m._id}
                      videoType={VIDEO_TYPE.TEST_VIDEO}
                      src={m.url}
                      size={{ width: 292, height: 164 }}
                      thumb={m.thumb}
                      className="rounded-10px overflow-hidden"
                    />
                    <Row className="justify-between px-3 text-black">
                      <div>{t(m.sportName)}</div>

                      <div>
                        {m.ability?.length > 0
                          ? m.ability.map((item, idx) => (
                              <div key={idx}>{t(item)}</div>
                            ))
                          : null}
                      </div>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </div>
      </div>

      {selectedVideo && (
        <VideoModal
          keyName={selectedVideo._id}
          videoType={VIDEO_TYPE.TEST_VIDEO}
          video={selectedVideo}
          onClose={() => setSelectedVideo(undefined)}
        />
      )}
    </Dashboard>
  )
}

export default VideosPage
