import './test-inputs.css'

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  message,
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '~/alpha/core'
import { useIsOnlineStore } from '~/context/online'
import { ILastYearOldResult } from '~/types/api/alpha/student/old-result-value'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import { convertJapNumbers } from '~/utils'
import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { isNumVal } from '~/utils/number'
import { IApiResExams } from '~/utils/types/api/alpha/exams'
import { ITestValResult } from '~/utils/types/api/alpha/test-result'
import { IStudentMe } from '~/utils/types/api/students'
import { Rt } from '../common/Rt'
import { EditIcon } from '../icons/EditIcon'
import { heightRules, weightRules } from './test-input/utils/rules'
import {
  InputConfig,
  getInputListEle,
  getNoEnduranceRunInputListEle,
} from './test-input/utils/input'
import { ITestResForm } from './type'
import { showEnduranceRunMinSec } from './utils'
import { currentTestsImage } from './test-input/utils/constants'

/**
 * unsupported case:
 * when input:
 * 0.999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999
 * to `InputNumber`, then the error occurred:
 * https://stackoverflow.com/questions/66903895/solution-for-number-tofixed-digits-argument-must-be-between-0-and-100-proble
 * Because there are already warning message about invalid value showing up to user.
 * 「小数点の入力はできません」または「小数点の入力は第1位までです」と表示されました
 */
export const TestInputs = ({
  testResults,
  student,
  hasEnduranceRun,
  isStudentInputActive,
  lastYearResult,
}: {
  testResults: IApiResExams[]
  student: IStudentMe
  hasEnduranceRun: boolean
  isStudentInputActive: boolean
  lastYearResult: ILastYearOldResult
}) => {
  const history = useHistory()

  const { t, i18n } = useTranslation()

  const [showMemoModals, setShowMemoModals] = useState<
    Record<string, boolean> | object
  >({})
  const [oriMemo, setOriMemo] = useState('')

  const [lastYearStudent, setLastYearStudent] = useState<ITestValResult>(
    {} as ITestValResult,
  )

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  const { isOnline } = useIsOnlineStore()

  const [form] = Form.useForm()

  const testResult = testResults.find((d) => d.testYear === currentYear)?.result

  const auth = useContext(AuthContext)
  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isJapanese(i18n)

  const getInputList = () => {
    return hasEnduranceRun
      ? getInputListEle(t, isUsingJpRuby, isUsingJp)
      : getNoEnduranceRunInputListEle(t, isUsingJpRuby, isUsingJp)
  }

  const _isNotMeasurable = {}

  getInputListEle(t, isUsingJpRuby, isUsingJp).forEach((input) => {
    _isNotMeasurable[input.key] = testResult?.[input.key]?.isNotMeasurable
  })

  const [isNotMeasurable, setIsNotMeasurable] = useState(_isNotMeasurable)

  useEffect(() => {
    if (testResult) {
      form.setFieldsValue({
        weight: testResult.sizeTest?.weight,
        height: testResult.sizeTest?.height,
        gripNotMeasurable: testResult.grip?.isNotMeasurable,
        gripMemo: testResult.grip?.memo,
        sitUpsNotMeasurable: testResult.sitUps?.isNotMeasurable,
        sitUpsMemo: testResult.sitUps?.memo,
        bendingNotMeasurable: testResult.bending?.isNotMeasurable,
        bendingMemo: testResult.bending?.memo,
        sideJumpNotMeasurable: testResult.sideJump?.isNotMeasurable,
        sideJumpMemo: testResult.sideJump?.memo,
        enduranceRunNotMeasurable: testResult.enduranceRun?.isNotMeasurable,
        enduranceRunMemo: testResult.enduranceRun?.memo,
        shuttleRunNotMeasurable: testResult.shuttleRun?.isNotMeasurable,
        shuttleRunMemo: testResult.shuttleRun?.memo,
        sprintRunNotMeasurable: testResult.sprintRun?.isNotMeasurable,
        sprintRunMemo: testResult.sprintRun?.memo,
        standingJumpNotMeasurable: testResult.standingJump?.isNotMeasurable,
        standingJumpMemo: testResult.standingJump?.memo,
        handballThrowNotMeasurable: testResult.handballThrow?.isNotMeasurable,
        handballThrowMemo: testResult.handballThrow?.memo,
        gripRight1: testResult.grip?.gripRight1,
        gripRight2: testResult.grip?.gripRight2,
        gripLeft1: testResult.grip?.gripLeft1,
        gripLeft2: testResult.grip?.gripLeft2,
        sitUps: testResult.sitUps?.sitUps,
        bending1: testResult.bending?.bending1,
        bending2: testResult.bending?.bending2,
        sideJump1: testResult.sideJump?.sideJump1,
        sideJump2: testResult.sideJump?.sideJump2,
        shuttleRunCount: testResult.shuttleRun?.shuttleRunCount,
        sprintRunSeconds: testResult.sprintRun?.sprintRunSeconds,
        standingJump1: testResult.standingJump?.standingJump1,
        standingJump2: testResult.standingJump?.standingJump2,
        handballThrow1: testResult.handballThrow?.handballThrow1,
        handballThrow2: testResult.handballThrow?.handballThrow2,
        enduranceRun: testResult.enduranceRun?.value || undefined,
        runningTime:
          testResult.enduranceRun?.runningTime === null ||
          !testResult.enduranceRun?.runningTime
            ? undefined
            : Number(testResult.enduranceRun?.runningTime),
        runningTimeSeconds:
          testResult.enduranceRun?.runningTimeSeconds === null ||
          !testResult.enduranceRun?.runningTimeSeconds
            ? undefined
            : Number(testResult.enduranceRun?.runningTimeSeconds),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResult])

  const currentTests: Record<
    string,
    { title: string | JSX.Element; suffix: string }
  > = hasEnduranceRun
    ? {
        height: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>身長</span>
              <rt className="font-normal text-xxs">しんちょう</rt>
            </ruby>
          ) : (
            t('身長')
          ),
          suffix: 'cm',
        },
        weight: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>体重</span>
              <rt className="font-normal text-xxs">たいじゅう</rt>
            </ruby>
          ) : (
            t('体重')
          ),
          suffix: 'kg',
        },
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: '回',
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs">ちょうざたいぜんくつ</rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横と</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              び
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: '点',
        },
        enduranceRun: { title: t('持久走'), suffix: '' },
        shuttleRun: { title: t('20mシャトルラン'), suffix: '回' },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: '秒',
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: { title: t('ボール投げ'), suffix: 'm' },
      }
    : {
        height: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>身長</span>
              <rt className="font-normal text-xxs">しんちょう</rt>
            </ruby>
          ) : (
            t('身長')
          ),
          suffix: 'cm',
        },
        weight: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>体重</span>
              <rt className="font-normal text-xxs">たいじゅう</rt>
            </ruby>
          ) : (
            t('体重')
          ),
          suffix: 'kg',
        },
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: '回',
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs">ちょうざたいぜんくつ</rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横と</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              {/* <ruby>
            <span>最新記録</span>
            <rt className="font-normal text-xxs">さいしんきろく</rt>
          </ruby> */}
              び
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: '点',
        },
        shuttleRun: { title: t('20mシャトルラン'), suffix: '回' },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: '秒',
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: {
          title: isUsingJpRuby ? (
            <span>
              ボール
              <ruby>
                <span>投</span>
                <rt className="font-normal text-xxs">な</rt>
              </ruby>
              げ
            </span>
          ) : (
            t('ボール投げ')
          ),
          suffix: 'm',
        },
      }

  useEffect(() => {
    let runningTime: null | number = null
    let runningTimeSeconds: null | number = null

    if (isNumVal(lastYearResult?.enduranceRunValue)) {
      const totalSeconds = Number(lastYearResult.enduranceRunValue)
      runningTimeSeconds = totalSeconds % 60
      runningTime = (totalSeconds - runningTimeSeconds) / 60
    }

    const lastYearTestResult: ITestValResult = {
      ...lastYearResult,
      bending: {
        value: lastYearResult?.bendingValue ?? null,
      },
      enduranceRun: {
        runningTime,
        runningTimeSeconds,
      },
      grip: {
        value: lastYearResult?.gripValue ?? null,
      },
      handballThrow: {
        value: lastYearResult?.handballThrowValue ?? null,
      },
      shuttleRun: {
        value: lastYearResult?.shuttleRunValue ?? null,
      },
      sideJump: {
        value: lastYearResult?.sideJumpValue ?? null,
      },
      sitUps: {
        value: lastYearResult?.sitUpsValue ?? null,
      },
      sprintRun: {
        value: lastYearResult?.sprintRunValue ?? null,
      },
      standingJump: {
        value: lastYearResult?.standingJumpValue ?? null,
      },
    }

    setLastYearStudent(lastYearTestResult)
  }, [lastYearResult])

  const showMemoModal = (key: string) => {
    setOriMemo(form.getFieldValue(`${key}Memo`) || testResult?.[key]?.memo)
    setShowMemoModals({ ...showMemoModals, [key]: true })
  }

  const hideMemoModal = (key: string, reset: boolean) => {
    if (reset) form.setFieldsValue({ [`${key}Memo`]: oriMemo })
    setShowMemoModals({ ...showMemoModals, [key]: false })
  }
  const [showRating, setShowRating] = useState<TestSubjectKey>('height')

  const spLastYearTitle = (
    <div className="flex justify-end">
      <div className="title-last-year flex">
        {isUsingJpRuby ? (
          <ruby className="font-black text-10px">
            <span className="py-8px">{t('前年度')}</span>
            <rt className="font-normal text-xxs">ぜんねんど</rt>
          </ruby>
        ) : (
          <span className="font-black text-10px py-8px">{t('前年度')}</span>
        )}
      </div>
    </div>
  )

  const renderInput = (input: InputConfig) => {
    const _testKey = testResult?.[input.key]

    return (
      <div key={input.key}>
        <div className="show-pc">
          <Row>
            <div
              className={`h-8 ${input.tab_width} bg-primary rounded-t-5px flex justify-center items-center text-white font-black`}
            >
              {input.tab}
            </div>
          </Row>
          <div className="flex justify-between items-center mb-3">
            <div className="rounded-5px rounded-5px-without-tl flex-1 bg-gray-150 p-1.5">
              <Row justify="center" align="middle">
                <Col span={22}>
                  <Row className="form-input-run">
                    {input.items.map((item, index) => {
                      return (
                        <Col
                          key={`${index}`}
                          span={6}
                          className="input-run-item"
                        >
                          <Row align="bottom">
                            <Form.Item
                              className="mb-0-f"
                              name={item.name}
                              label={
                                item.label ? (
                                  <span className="text-10px">
                                    {item.label}
                                  </span>
                                ) : null
                              }
                              rules={
                                input.measurable &&
                                isNotMeasurable[input.key] === true
                                  ? []
                                  : item.rules
                              }
                              validateTrigger={
                                input.measurable &&
                                isNotMeasurable[input.key] === true
                                  ? []
                                  : ['onSubmit', 'onChange']
                              }
                            >
                              <InputNumber
                                className="border-primary"
                                type="number"
                                inputMode="decimal"
                                step="any"
                                disabled={isNotMeasurable[input.key]}
                                parser={(displayValue) => {
                                  return convertJapNumbers(
                                    displayValue as string,
                                  )
                                }}
                              />
                            </Form.Item>
                            {item.suffix && (
                              <div className="pl-0.5">
                                <span className="text-10px">
                                  {t(item.suffix)}
                                </span>
                              </div>
                            )}
                          </Row>
                        </Col>
                      )
                    })}
                    {input.measurable && (
                      <Col
                        key="measurable"
                        span={input.items.length >= 4 ? 24 : 10}
                        className={`${
                          input.items.length < 4 && 'ml-2'
                        } input-run-measure`}
                      >
                        {input.items?.[0]?.label && input.items.length < 4 ? (
                          <div className="h-6" />
                        ) : (
                          <div />
                        )}
                        <Row align="middle">
                          <Form.Item
                            className="mb-0-f"
                            name={`${input.key}NotMeasurable`}
                            valuePropName="checked"
                          >
                            <Checkbox
                              className="text-10px font-normal"
                              onChange={(event) => {
                                const newValue = {
                                  ...isNotMeasurable,
                                }
                                newValue[input.key] = event.target.checked
                                setIsNotMeasurable(newValue)
                                form.validateFields()
                              }}
                            >
                              {isUsingJpRuby ? (
                                <ruby>
                                  <span>今年度測定</span>
                                  <Rt className="text-10px">
                                    こんねんどそくてい
                                  </Rt>
                                  なし
                                </ruby>
                              ) : (
                                <span>{t('今年度測定なし')}</span>
                              )}
                            </Checkbox>
                          </Form.Item>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={2}>
                  <Row justify="center" align="middle">
                    <Button
                      className="button-memo"
                      type="primary"
                      icon={EditIcon}
                      onClick={() => showMemoModal(input.key)}
                    />
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="w-30 flex flex-col items-end relative">
              {/* PC 前年度 */}
              {/* The button is slightly shifted to the right, so use `right-2` */}
              <div className="absolute -top-6 font-black text-10px right-2">
                {isUsingJpRuby ? (
                  <ruby>
                    前年度
                    <Rt className="text-10px">ぜんねんど</Rt>
                    のスコア
                  </ruby>
                ) : (
                  t('前年度')
                )}
              </div>
              <Row
                justify="end"
                align="middle"
                className={'w-20 h-9 rounded-5px mr-1 p-2 speech-bubble-gray'}
              >
                {input.key === 'enduranceRun' ? (
                  <span
                    className="relative z-10 text-base"
                    style={{ fontSize: isUsingJp ? 14 : 10 }}
                  >
                    {showEnduranceRunMinSec(lastYearStudent, t)}
                  </span>
                ) : (
                  <span className="relative z-10 text-base">{`${`${
                    lastYearStudent[input.key]
                      ? (lastYearStudent[input.key].value ?? '-')
                      : '-'
                  }`} ${t(input.items[0].suffix)}`}</span>
                )}
              </Row>
            </div>
          </div>
        </div>

        <div className="show-sp">
          <div className="form-test-sp mt-4" style={{ maxWidth: '100%' }}>
            {spLastYearTitle}
            <div className="flex justify-center mt-3 mb-10">
              <div>
                {input.items.map((item) => {
                  return (
                    <div
                      key={item.name}
                      className="input-run-item"
                      style={{
                        display: 'inline-block',
                        width: '50%',
                        minWidth: 120,
                      }}
                    >
                      <Row align="bottom">
                        <Form.Item
                          className="mb-0-f"
                          name={item.name}
                          label={
                            item.label ? (
                              <span className="text-10px">{item.label}</span>
                            ) : null
                          }
                          rules={
                            input.measurable &&
                            isNotMeasurable[input.key] === true
                              ? []
                              : item.rules
                          }
                          validateTrigger={
                            input.measurable &&
                            isNotMeasurable[input.key] === true
                              ? []
                              : ['onSubmit', 'onChange']
                          }
                        >
                          <InputNumber
                            className="border-primary"
                            type="number"
                            inputMode="decimal"
                            step="any"
                            disabled={isNotMeasurable[input.key]}
                            parser={(displayValue) => {
                              return convertJapNumbers(displayValue as string)
                            }}
                          />
                        </Form.Item>
                        {item.suffix && (
                          <div className="px-1">
                            <span
                              style={{
                                fontSize: 16,
                                color: '#333333',
                                height: 16,
                              }}
                            >
                              {t(item.suffix)}
                            </span>
                          </div>
                        )}
                      </Row>
                    </div>
                  )
                })}
              </div>
              {input.key === 'enduranceRun' ? (
                <div className="flex justify-end items-center ml-4">
                  <div className="mt-1 mr-1">
                    <span className="mr-1 text-base">{`${
                      lastYearStudent[input.key]
                        ? (lastYearStudent[input.key].runningTime ?? '-')
                        : '-'
                    }`}</span>
                    <span className="text-base color-gray3">{t('分')}</span>
                  </div>

                  <div className="mt-1">
                    <span className="mr-1 text-base">{`${
                      lastYearStudent[input.key]
                        ? (lastYearStudent[input.key].runningTimeSeconds ?? '-')
                        : '-'
                    }`}</span>
                    <span className="text-base color-gray3">{t('秒')}</span>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex items-center ${
                    input.key === 'grip' ? 'mt-5' : ''
                  }`}
                  style={{
                    minWidth: 50,
                    ...(['sitUps', 'sprintRun'].includes(input.key)
                      ? {
                          marginTop: '0.25rem',
                          marginLeft: 'auto',
                        }
                      : {
                          marginTop: 27,
                          marginLeft: 40,
                        }),
                  }}
                >
                  <span className="mr-1 text-base">{`${
                    lastYearStudent[input.key]
                      ? (lastYearStudent[input.key].value ?? '-')
                      : '-'
                  }`}</span>
                  <span className="text-base color-gray3">
                    {t(input.suffix as string)}
                  </span>
                </div>
              )}
            </div>
            <Row justify="center" align="middle">
              <Button
                className="button-memo mr-2"
                type="primary"
                onClick={() => showMemoModal(input.key)}
              >
                {t('メモ')}
              </Button>
              <div className="ml-2">
                {input.measurable && (
                  <Col
                    span={24}
                    className={`${
                      input.items.length < 4 && 'ml-2'
                    } input-run-measure`}
                  >
                    {input.items?.[0]?.label && input.items.length < 4 ? (
                      <div className="h-6" />
                    ) : (
                      <div />
                    )}
                    <Row align="middle">
                      <Form.Item
                        className="mb-0-f"
                        name={`${input.key}NotMeasurable`}
                        valuePropName="checked"
                      >
                        <Checkbox
                          className="text-10px font-normal"
                          onChange={(event) => {
                            const newValue = {
                              ...isNotMeasurable,
                            }
                            newValue[input.key] = event.target.checked
                            setIsNotMeasurable(newValue)
                            form.validateFields()
                          }}
                        >
                          {isUsingJpRuby ? (
                            <ruby>
                              <span>今年度測定</span>
                              <Rt className="text-10px">こんねんどそくてい</Rt>
                              なし
                            </ruby>
                          ) : (
                            <span>{t('今年度測定なし')}</span>
                          )}
                        </Checkbox>
                      </Form.Item>
                    </Row>
                  </Col>
                )}
              </div>
            </Row>
          </div>
        </div>

        <Modal
          visible={showMemoModals[input.key]}
          onCancel={() => hideMemoModal(input.key, true)}
          width={800}
          closeIcon={<CloseOutlined className="text-base color-blue-f" />}
          footer={null}
        >
          <div className="p-5 text-center">
            {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
            <label className="text-2xl font-black">{t('メモ')}</label>
            <Form.Item
              className="mb-0-f"
              name={`${input.key}Memo`}
              initialValue={_testKey?.memo}
            >
              <Input.TextArea
                style={{ height: 400 }}
                key={`${input.key}-memo`}
                bordered={false}
                autoFocus={true}
              />
            </Form.Item>
            <div className="w-full flex justify-center">
              <Button
                className="w-60 rounded"
                type="primary"
                size="large"
                onClick={() => hideMemoModal(input.key, false)}
              >
                {isUsingJpRuby ? (
                  <ruby>
                    <span style={{ marginLeft: 2, marginRight: 2 }}>登録</span>
                    <rt className="text-10px">とうろく</rt>
                  </ruby>
                ) : (
                  <span>{t('登録')}</span>
                )}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  const onFinish = async (values: ITestResForm) => {
    const data = {
      grip: {
        gripRight1: values.gripRight1,
        gripRight2: values.gripRight2,
        gripLeft1: values.gripLeft1,
        gripLeft2: values.gripLeft2,
        isNotMeasurable: values.gripNotMeasurable,
        memo: values.gripMemo,
      },
      sitUps: {
        sitUps: values.sitUps,
        isNotMeasurable: values.sitUpsNotMeasurable,
        memo: values.sitUpsMemo,
      },
      bending: {
        bending1: values.bending1,
        bending2: values.bending2,
        isNotMeasurable: values.bendingNotMeasurable,
        memo: values.bendingMemo,
      },
      sideJump: {
        sideJump1: values.sideJump1,
        sideJump2: values.sideJump2,
        isNotMeasurable: values.sideJumpNotMeasurable,
        memo: values.sideJumpMemo,
      },
      enduranceRun: {
        runningTime: values.runningTime?.toString(),
        runningTimeSeconds: values.runningTimeSeconds,
        isNotMeasurable: values.enduranceRunNotMeasurable,
        memo: values.enduranceRunMemo,
      },
      shuttleRun: {
        shuttleRunCount: values.shuttleRunCount,
        //shuttleRunGainOxygen?
        isNotMeasurable: values.shuttleRunNotMeasurable,
        memo: values.shuttleRunMemo,
      },
      sprintRun: {
        sprintRunSeconds: values.sprintRunSeconds,
        isNotMeasurable: values.sprintRunNotMeasurable,
        memo: values.sprintRunMemo,
      },
      standingJump: {
        standingJump1: values.standingJump1,
        standingJump2: values.standingJump2,
        isNotMeasurable: values.standingJumpNotMeasurable,
        memo: values.standingJumpMemo,
      },
      handballThrow: {
        handballThrow1: values.handballThrow1,
        handballThrow2: values.handballThrow2,
        isNotMeasurable: values.handballThrowNotMeasurable,
        memo: values.handballThrowMemo,
      },
      sizeTest: {
        weight: values.weight,
        height: values.height,
      },
    }

    try {
      setLoading(true)
      if (isOnline) {
        // console.log('🚀 ~ onFinish ~ data:', data)
        const res = await Api.post('/alpha/v1/student/result', data)

        if (res.status === 500) {
          message.error(`${t('エラーが発生しました。')} [${res.data.error}]`)
          setLoading(false)
          return
        }

        message.success(t('登録完了'))
        history.push('/result')
      } else {
        setSubmit(true)
        localStorage.setItem('form_test_alpha', JSON.stringify(data))
        message.warning(
          t('保存しました。オンライン環境になると自動でデータが登録されます。'),
        )
      }
      setLoading(false)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
    }
  }

  const onSubmitForm = async (values: ITestResForm) => {
    try {
      // console.log('🚀 ~ onSubmitForm ~ values:', values)
      const res = await Api.post('/alpha/v1/student/result', values)

      if (res.status === 200) {
        message.success(t('登録完了'))
        localStorage.removeItem('form_test_alpha')
        history.push('/result')
        return
      }
      if (res.status === 500) {
        message.error(`${t('エラーが発生しました。')} [${res.data.error}]`)
        return
      }
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
    }
  }

  // TODO: refactor this
  useEffect(() => {
    const submitWhenOnline = () => {
      if (submit && student) {
        const dataTest = localStorage.getItem('form_test_alpha')

        if (dataTest) {
          const dataForm: ITestResForm = JSON.parse(dataTest)
          if (dataForm) {
            onSubmitForm(dataForm)
          } else {
            localStorage.removeItem('form_test_alpha')
          }
        }
      }
    }

    window.addEventListener('online', submitWhenOnline)

    return () => {
      window.removeEventListener('online', submitWhenOnline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          gripRight1: testResult?.grip?.gripRight1,
        }}
        hideRequiredMark={true}
        className="form-test-input"
      >
        <div className="show-pc">
          <Row className="form-weight-height">
            <Col span={6}>
              <Row>
                <div
                  className={`h-8 bg-primary rounded-t-5px flex justify-center items-center text-white font-black${
                    isUsingJp ? ' w-16' : ' w-20'
                  }`}
                >
                  {isUsingJpRuby ? (
                    // Set padding to reduce the size between 2 Kanji characters.
                    <ruby>
                      <span className="py-11px">身長</span>
                      <Rt className="text-10px">しんちょう</Rt>
                    </ruby>
                  ) : (
                    t('身長')
                  )}
                </div>
              </Row>
              <div className="flex justify-between items-center mb-3 w-60">
                <div className="w-30 rounded-5px rounded-5px-without-tl flex-1 bg-primary p-1.5">
                  {/* Height */}
                  <div>
                    <div className="flex relative w-30">
                      <Form.Item
                        className="mb-0-f"
                        name="height"
                        rules={heightRules(t, isUsingJpRuby)}
                        validateTrigger={['onSubmit', 'onChange']}
                      >
                        <InputNumber
                          className="border-primary"
                          type="number"
                          step="any"
                          inputMode="decimal"
                          parser={(displayValue) => {
                            return convertJapNumbers(displayValue as string)
                          }}
                        />
                      </Form.Item>
                      <div className="flex items-end absolute left-24 top-4">
                        <span className="text-10px text-white">cm</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-30 flex flex-col items-end relative">
                  {/* PC First line 前年度 */}
                  <div className="pr-3 absolute -top-6">
                    <ruby className="font-black text-10px">
                      <span className="py-8px">{t('前年度')}</span>
                      {isUsingJpRuby && (
                        <Rt className="text-10px">ぜんねんど</Rt>
                      )}
                    </ruby>
                  </div>
                  <Row
                    justify="end"
                    align="middle"
                    className={'w-20 h-9 rounded-5px p-2 speech-bubble-gray'}
                  >
                    <span className="relative z-10 text-base">
                      {`${lastYearStudent?.sizeTest?.height ?? '-'} cm`}
                    </span>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <Row>
                <div
                  className={`h-8 bg-primary rounded-t-5px flex justify-center items-center text-white font-black${
                    isUsingJp ? ' w-16' : ' w-20'
                  }`}
                >
                  {isUsingJpRuby ? (
                    <ruby>
                      <span className="py-11px">体重</span>
                      <Rt className="text-10px">たいじゅう</Rt>
                    </ruby>
                  ) : (
                    t('体重')
                  )}
                </div>
              </Row>

              <div className="flex justify-between items-center mb-3 w-60">
                <div className="w-30 rounded-5px rounded-5px-without-tl flex-1 bg-primary p-1.5">
                  {/* weight */}
                  <div>
                    <div className="flex relative w-30">
                      <Form.Item
                        className="mb-0-f"
                        name="weight"
                        rules={weightRules(t, isUsingJpRuby)}
                        validateTrigger={['onSubmit', 'onChange']}
                      >
                        <InputNumber
                          className="border-primary"
                          type="number"
                          step="any"
                          inputMode="decimal"
                          parser={(displayValue) => {
                            return convertJapNumbers(displayValue as string)
                          }}
                        />
                      </Form.Item>
                      <div className="flex items-end absolute left-24 top-4">
                        <span className="text-10px text-white">kg</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-30 flex flex-col items-end relative">
                  {/* PC First line 前年度 */}
                  <div className="pr-3 absolute -top-6">
                    <ruby className="font-black text-10px">
                      <span className="py-8px">{t('前年度')}</span>
                      {isUsingJpRuby && (
                        <Rt className="text-10px">ぜんねんど</Rt>
                      )}
                    </ruby>
                  </div>
                  <Row
                    justify="end"
                    align="middle"
                    className={'w-20 h-9 rounded-5px p-2 speech-bubble-gray'}
                  >
                    <span className="relative z-10 text-base">
                      {`${lastYearStudent?.sizeTest?.weight ?? '-'} kg`}
                    </span>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="form-test">
            <Col span={12} className="pr-2 flex justify-evenly form-test-item">
              <div className="h-full flex flex-col justify-between">
                {getInputList()
                  .slice(0, 4)
                  .map((input) => renderInput(input))}
              </div>
            </Col>
            <Col span={12} className="pl-2 form-test-item">
              <div className="h-full flex flex-col justify-between">
                {getInputList()
                  .slice(4)
                  .map((input) => renderInput(input))}
              </div>
            </Col>
          </Row>
        </div>

        <div className="show-sp">
          <div className="grid grid-cols-5 list-exam-graph list-exam-graph-test mb-6 mt-0">
            {Object.keys(currentTests).map((key) => {
              return (
                <div
                  key={key}
                  className={`${
                    showRating === key ? 'exam-active' : ''
                  } text-center`}
                  onClick={() => setShowRating(key as TestSubjectKey)}
                  style={key === 'shuttleRun' ? { fontSize: 10 } : {}}
                >
                  {currentTests[key].title}
                </div>
              )
            })}
          </div>
          <div className="mb-10 block">
            <div className={showRating === 'height' ? '' : 'hidden'}>
              <div className="title-test-sp">
                <span>{currentTests.height.title}</span>
                <img src={currentTestsImage.height} alt="" />
              </div>

              <div className="form-test-sp mt-4">
                {spLastYearTitle}
                <div className="flex justify-center mt-3">
                  <div className="flex relative w-30 items-center mr-12">
                    <Form.Item
                      className="mb-0-f"
                      name="height"
                      rules={heightRules(t, isUsingJpRuby)}
                      validateTrigger={['onSubmit', 'onChange']}
                    >
                      <InputNumber
                        className="border-primary"
                        type="number"
                        step="any"
                        inputMode="decimal"
                        parser={(displayValue) => {
                          return convertJapNumbers(displayValue as string)
                        }}
                      />
                    </Form.Item>
                    <div className="ml-1">
                      <span style={{ fontSize: 16, color: '#333333' }}>cm</span>
                    </div>
                  </div>
                  <div className="flex items-center mt-1">
                    <span className="mr-4" style={{ fontSize: 16 }}>{`${
                      lastYearStudent?.sizeTest?.height ?? '-'
                    }`}</span>
                    <span style={{ fontSize: 16, color: '#333333' }}>cm</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={showRating === 'weight' ? '' : 'hidden'}>
              <div className="title-test-sp">
                <span>{currentTests.weight.title}</span>
                <img src={currentTestsImage.weight} alt="" />
              </div>
              <div className="form-test-sp mt-4">
                {spLastYearTitle}
                <div className="flex justify-center mt-3">
                  <div className="flex relative w-30 items-center mr-12">
                    <Form.Item
                      className="mb-0-f"
                      name="weight"
                      rules={weightRules(t, isUsingJpRuby)}
                      validateTrigger={['onSubmit', 'onChange']}
                    >
                      <InputNumber
                        className="border-primary"
                        type="number"
                        step="any"
                        inputMode="decimal"
                        parser={(displayValue) => {
                          return convertJapNumbers(displayValue as string)
                        }}
                      />
                    </Form.Item>
                    <div className="ml-1">
                      <span style={{ fontSize: 16, color: '#333333' }}>kg</span>
                    </div>
                  </div>
                  <div className="flex items-center mt-1">
                    <span className="mr-4" style={{ fontSize: 16 }}>{`${
                      lastYearStudent?.sizeTest?.weight ?? '-'
                    }`}</span>
                    <span style={{ fontSize: 16, color: '#333333' }}>kg</span>
                  </div>
                </div>
              </div>
            </div>
            {getInputList().map((input, index) => (
              <div
                className={showRating === input.key ? '' : 'hidden'}
                key={index}
              >
                <div className="title-test-sp">
                  <span>{currentTests[input.key]?.title}</span>
                  <img src={currentTestsImage[input.key]} alt="" />
                </div>
                {renderInput(input)}
              </div>
            ))}
          </div>
        </div>
        <Row justify="center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-60"
              size="large"
              loading={loading}
              disabled={!isStudentInputActive}
            >
              {isUsingJpRuby ? (
                <ruby>
                  <span style={{ marginLeft: 2, marginRight: 2 }}>
                    {'登録'}
                  </span>
                  <rt className="text-10px">とうろく</rt>
                </ruby>
              ) : (
                <span>{t('登録')}</span>
              )}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  )
}
